/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { MDBBtn, MDBBtnGroup, MDBRadio } from "mdb-react-ui-kit";
import axios from "axios";
import { ClapSpinner } from "react-spinners-kit";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Icon from "react-icons-kit";
import { software_pencil } from "react-icons-kit/linea/software_pencil";
import { arrows_plus } from "react-icons-kit/linea/arrows_plus";
import { alertCircle } from "react-icons-kit/feather/alertCircle";
import Tippy from "@tippyjs/react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import apiKey from "../private/private";
import toast, { Toaster } from "react-hot-toast";
import UpdatePhotoPopup from "./UpdatePhotoPopup";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007c16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007c16" : "none",
    "&:hover": {
      border: "1px solid #007c16",
      boxShadow: "0px 0px 1px #007c16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007c16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007c16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const ProfileForm = ({ hostName, userData, setLoggedInUserData }) => {
  // step
  const [step, setStep] = useState("profile"); // profile // password // profileImage

  // hook
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey,
    debounce: 1000,
    options: {
      componentRestrictions: {
        country: ["ca", "us"],
      },
      fields: ["address_components"],
      types: ["address"],
    },
  });

  // states
  const [selectedPrediction, setSelectedPrediction] = useState(null);
  const [placesPopup, setPlacesPopup] = useState(false);

  // input change
  const handleInputChange = (evt) => {
    const inputValue = evt.target.value;
    setStreetAddress(inputValue);
    getPlacePredictions({ input: inputValue });
    setSelectedPrediction(null);
    setPlacesPopup(true);
  };

  // prediction click
  const handlePredictionClick = (prediction) => {
    setSelectedPrediction(prediction);
    setPlacesPopup(false);

    // Extract address components from the prediction
    placesService?.getDetails(
      {
        placeId: prediction.place_id,
      },
      (place) => {
        const addressComponents = place.address_components;
        let mergedStreetAddress = "";

        // Loop through address components to find street number and route
        addressComponents.forEach((component) => {
          switch (component.types[0]) {
            case "street_number":
            case "route":
              mergedStreetAddress += `${component.long_name} `;
              break;
            case "locality":
              setCity(component.long_name);
              break;
            case "administrative_area_level_1":
              setProvinceOrState(component.short_name);
              break;
            case "country":
              setCountry(component.long_name);
              break;
            case "postal_code":
              setPostalZipCode(component.long_name);
              break;
            default:
              console.warn(
                "Unhandled address component type:",
                component.types[0]
              );
              // You can add additional handling for unhandled address component types here
              break;
          }
        });

        // Update the street address state variable with the merged street number and route
        setStreetAddress(mergedStreetAddress.trim());
      }
    );
  };

  // form fields
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [phoneNo, setPhoneNo] = useState("");

  const [formattedPhone, setFormattedPhone] = useState(""); // Formatted number

  const formatPhoneNumber = (value) => {
    if (value) {
      // Remove all non-numeric characters
      const digits = value.replace(/\D/g, "");

      // Format as (555) 555-1234
      if (digits.length <= 3) {
        return `(${digits}`;
      } else if (digits.length <= 6) {
        return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
      } else {
        return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(
          6,
          10
        )}`;
      }
    } else {
      return "";
    }
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value;

    // Remove all non-numeric characters
    const digits = input.replace(/\D/g, "");

    // Restrict length to 10 digits for North American numbers
    if (digits.length <= 10) {
      const formatted = formatPhoneNumber(digits);
      setPhoneNo(digits); // Update raw state for API payload
      setFormattedPhone(formatted); // Update formatted state for UI
    }
  };

  const [email, setEmail] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [provinceOrState, setProvinceOrState] = useState("");
  const [postalZipCode, setPostalZipCode] = useState("");
  const [pattern, setPattern] = useState("");

  useEffect(() => {
    if (country === "United States") {
      setPattern("^\\d{5}$|^\\d{5}-\\d{4}$|^\\d{5} \\d{4}$");
    } else {
      setPattern("^[A-Za-z]\\d[A-Za-z] \\d[A-Za-z]\\d$");
    }
  }, [country]);

  // donor type
  const [donorType, setDonorType] = useState("I");
  const handleDonorTypeChange = (event) => {
    setDonorType(event.target.id);
    setBusinessName("");
  };

  // business name
  const [businessName, setBusinessName] = useState("");

  // event states
  const [error, setError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [loader, setLoader] = useState(true);

  // countries, usaStates, provinces
  const [countries, setCountries] = useState([]);
  const [usaStates, setUsaStates] = useState([]);
  const [provinces, setProvinces] = useState([]);

  // navigate
  const navigate = useNavigate();

  // axios call to get data and all options from server
  useEffect(() => {
    if (userData) {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${userData.token}`,
        },
      };
      Promise.all([
        // get profile data
        axios.get(`${hostName}api/v1/auth/profile`, axiosConfig),
        // get countries
        axios.get(`${hostName}api/v1/public/country`),
        // get usa states
        axios.get(`${hostName}api/v1/public/state?countryCode=US`),
        // get canada provinces
        axios.get(`${hostName}api/v1/public/state?countryCode=ca`),
      ])
        .then(([profileRes, countriesRes, usaStatesRes, provincesRes]) => {
          // profile res
          setFirstName(profileRes.data.data.firstName);
          setMiddleName(profileRes.data.data.middleName);
          setLastName(profileRes.data.data.lastName);
          setPhoneNo(profileRes.data.data.phone);
          // change the raw to formatted on component mount
          if (profileRes.data.data.phone.length <= 10) {
            setFormattedPhone(formatPhoneNumber(profileRes.data.data.phone));
          } else {
            setFormattedPhone(profileRes.data.data.phone);
          }
          setEmail(profileRes.data.data.email);
          setStreetAddress(profileRes.data.data.streetAddress);
          setCity(profileRes.data.data.city);
          setCountry(profileRes.data.data.country);
          setProvinceOrState(profileRes.data.data.provinceState);
          setPostalZipCode(profileRes.data.data.postalZipCode);
          setDonorType(profileRes.data.data.accountType);
          setBusinessName(
            profileRes.data.data.businessName
              ? profileRes.data.data.businessName
              : ""
          );

          // Handle countries
          const countryOptions = countriesRes.data.map((country) => ({
            value: country.code,
            label: country.name,
          }));
          setCountries(countryOptions);

          // Handle USA states
          const usaStatesOptions = usaStatesRes.data.map((state) => ({
            value: state.code,
            label: state.name,
          }));
          setUsaStates(usaStatesOptions);

          // Handle Canada provinces
          const provincesOptions = provincesRes.data.map((province) => ({
            value: province.code,
            label: province.name,
          }));
          setProvinces(provincesOptions);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            localStorage.removeItem("user");
            setLoggedInUserData(null);
            navigate("/signin");
          } else {
            setError(
              err.response.data.messageDetails ||
                err.response.data.message ||
                err.message
            );
          }
        })
        .finally(() => setLoader(false));
    } else {
      navigate("/signin");
    }
  }, []);

  // handle profile changes
  const handleProfileChanges = (e) => {
    e.preventDefault();
    setLoader(true);
    setError(null);
    setSuccessMsg(null);
    const data = {
      firstName,
      middleInitials: middleName,
      lastName,
      phone: phoneNo,
      email,
      streetAddress,
      city,
      country,
      provinceState: provinceOrState,
      postalZipCode,
      allowContact: "1",
      notifyNewCampaign: "1",
      accountType: donorType,
    };

    if (donorType === "B") {
      data.businessName = businessName;
    }
    axios
      .put(`${hostName}api/v1/auth/update`, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        setSuccessMsg(res.data.message);
        const updatedUser = {
          ...userData,
          email,
          firstName,
          lastName,
          middleInitials: middleName,
        };
        localStorage.setItem("user", JSON.stringify(updatedUser));
        setLoggedInUserData(updatedUser);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("user");
          setLoggedInUserData(null);
          navigate("/signin");
        } else {
          setError(
            err.response.data.messageDetails ||
              err.response.data.message ||
              err.message
          );
          setPlacesPopup(false);
        }
      })
      .finally(() => setLoader(false));
  };

  // handle country change
  const handleCountryChange = (value) => {
    setProvinceOrState("");
    setCountry(value);
    setPostalZipCode("");
  };

  // change password
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorInChangingPassword, setErrorInChangingPassword] = useState(null);
  const [passwordSuccessMsg, setPasswordSuccessMsg] = useState(null);

  const handleChangePassword = (e) => {
    e.preventDefault();
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      setErrorInChangingPassword(
        "Password must be atleast 8 alphanumeric characters."
      );
    } else if (confirmNewPassword !== newPassword) {
      setErrorInChangingPassword(
        "Please confirm your password. Make sure it matches the password you entered."
      );
    } else {
      setLoader(true);
      setErrorInChangingPassword(null);
      setPasswordSuccessMsg(null);
      axios
        .put(
          `${hostName}api/v1/auth/changePassword`,
          {
            currentPassword,
            newPassword,
            confirmPassword: confirmNewPassword,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${userData.token}`,
            },
          }
        )
        .then((res) => {
          setPasswordSuccessMsg(res.data.message);
          setTimeout(() => {
            localStorage.removeItem("user");
            setLoggedInUserData(null);
            navigate("/signin");
          }, 5000);
          setCurrentPassword("");
          setNewPassword("");
          setConfirmNewPassword("");
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            localStorage.removeItem("user");
            setLoggedInUserData(null);
            navigate("/signin");
          }
          setErrorInChangingPassword(
            err.response.data.messageDetails ||
              err.response.data.message ||
              err.message
          );
        })
        .finally(() => setLoader(false));
    }
  };

  // is user dp loaded
  const [isLoaded, setIsLoaded] = useState(false);

  // set is loaded to false again when user change his dp
  useEffect(() => {
    setIsLoaded(false);
  }, [userData?.profileImage]);

  // delete photo
  const [deletePhotoLoading, setDeletePhotoLoading] = useState(false);
  const handleDeletePhoto = () => {
    setDeletePhotoLoading(true);
    setFileError(null);
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
    };
    axios
      .delete(`${hostName}api/v1/donor/deleteProfileImage`, axiosConfig)
      .then((res) => {
        toast.success(`${res.data.messageDetails}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        // Update userData.profileImage to null
        const updatedUser = {
          ...userData,
          profileImage: null,
        };

        // Save the updated user data to localStorage
        localStorage.setItem("user", JSON.stringify(updatedUser));

        // Update the state with the new userData
        setLoggedInUserData(updatedUser);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("user");
          setLoggedInUserData(null);
          navigate("/signin");
        } else {
          toast.error(
            `${
              err.response.data.messageDetails ||
              err.response.data.message ||
              err.message
            }`,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      })
      .finally(() => setDeletePhotoLoading(false));
  };

  // profile photo upload
  const [photoFile, setPhotoFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [updatePhotoPopup, setUpdatePhotoPopup] = useState(false);

  const handleProfilePhoto = (file) => {
    if (file) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
      const fileType = file.type;

      // Maximum file size in KB
      const maxFileSizeKB = 1024;

      // Check file type
      if (!allowedFileTypes.includes(fileType)) {
        setPhotoFile(null);
        setPhotoURL(null);
        setFileError("Invalid file type. Only PNG, JPEG, and JPG.");
        return;
      }

      // Check file size
      if (file.size > maxFileSizeKB * 1024) {
        setPhotoFile(null);
        setPhotoURL(null);
        setFileError(`File size should not exceed ${maxFileSizeKB}KB.`);
        return;
      }

      // File is valid
      setPhotoFile(file);
      setFileError(null);

      // Create object URL and save it in the state
      const url = URL.createObjectURL(file);
      setPhotoURL(url);

      // open popup
      setUpdatePhotoPopup(true);
    } else {
      setFileError(null);
    }
  };

  // translator
  const { t } = useTranslation();

  return (
    <>
      {updatePhotoPopup && (
        <UpdatePhotoPopup
          hostName={hostName}
          token={userData.token}
          userData={userData}
          setLoggedInUserData={setLoggedInUserData}
          setUpdatePhotoPopup={setUpdatePhotoPopup}
          photoURL={photoURL}
          photoFile={photoFile}
        />
      )}
      <Toaster />
      <div className="home-content">
        {loader ? (
          <main className="info-content-div" style={{ height: `${100}%` }}>
            <div className="loading-screen">
              <ClapSpinner size={30} color="#007c16" loading={loader} />
            </div>
          </main>
        ) : (
          <div className="profile-div">
            <div className="options-div">
              <MDBBtn
                type="button"
                className={`profile-options ${
                  step === "profile" ? " active" : ""
                }`}
                onClick={() => setStep("profile")}
                disabled={loader}
              >
                {donorType === "B"
                  ? `${t("Profile.update_contact")}`
                  : `${t("Profile.update_profile")}`}
              </MDBBtn>
              <MDBBtn
                type="button"
                className={`profile-options ${
                  step === "password" ? " active" : ""
                }`}
                onClick={() => setStep("password")}
                disabled={loader}
              >
                {t("Profile.change_password")}
              </MDBBtn>
              <MDBBtn
                type="button"
                className={`profile-options ${
                  step === "profileImage" ? " active" : ""
                }`}
                onClick={() => setStep("profileImage")}
                disabled={loader}
              >
                {t("Profile.update_profile_img")}
              </MDBBtn>
            </div>
            <br />

            {/* profile */}
            {step === "profile" && (
              <form
                className="auth-form"
                autoComplete="off"
                onSubmit={handleProfileChanges}
              >
                {successMsg && <div className="user-message">{successMsg}</div>}

                <h5 className="auth-heading">{t("Signup.donor_type")}</h5>
                <MDBBtnGroup className="custom-btn-group">
                  <MDBRadio
                    btn
                    btnColor={`${
                      donorType === "I" ? "theme-green" : "transparent"
                    }`}
                    id="I"
                    name="donorTypeOptions"
                    wrapperTag="span"
                    label={t("Signup.individual")}
                    checked={donorType === "I"}
                    onChange={handleDonorTypeChange}
                  />
                  <MDBRadio
                    btn
                    btnColor={`${
                      donorType === "B" ? "theme-green" : "transparent"
                    }`}
                    id="B"
                    name="donorTypeOptions"
                    wrapperClass="mx-2"
                    wrapperTag="span"
                    label={t("Signup.business")}
                    checked={donorType === "B"}
                    onChange={handleDonorTypeChange}
                  />
                </MDBBtnGroup>

                {donorType === "B" && (
                  <>
                    <div className="custom-row">
                      <div className="custom-col">
                        <label>{t("Signup.business_name")}</label>
                        <input
                          type="text"
                          className="edit-media-inputs"
                          required={donorType === "B"}
                          value={businessName || ""}
                          onChange={(e) => setBusinessName(e.target.value)}
                          placeholder={t("Signup.business_name")}
                        />
                      </div>
                    </div>
                    <br />
                  </>
                )}

                {/* first middle last name */}
                <div className="custom-row">
                  <div className="custom-col">
                    <label>{t("Signup.first_name")}</label>
                    <input
                      type="text"
                      className="edit-media-inputs"
                      required
                      placeholder={t("Signup.first_name")}
                      value={firstName || ""}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="custom-col">
                    <label>{t("Signup.middle_name")}</label>
                    <input
                      type="text"
                      className="edit-media-inputs"
                      placeholder={t("Signup.middle_name")}
                      value={middleName || ""}
                      onChange={(e) => setMiddleName(e.target.value)}
                    />
                  </div>
                  <div className="custom-col">
                    <label>{t("Signup.last_name")}</label>
                    <input
                      type="text"
                      className="edit-media-inputs"
                      required
                      placeholder={t("Signup.last_name")}
                      value={lastName || ""}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>

                {/* email and phone */}
                <div className="custom-row">
                  <div className="custom-col">
                    <label>{t("Signup.phone")}</label>
                    <input
                      type="tel"
                      inputMode="numeric"
                      pattern="^\(\d{3}\) \d{3}-\d{4}$"
                      title={t("Signup.phonePatternMsg")}
                      maxLength={14}
                      className="edit-media-inputs"
                      required
                      value={isFocused ? phoneNo : formattedPhone}
                      onChange={handlePhoneChange}
                      placeholder="5555551234"
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                    />
                  </div>
                  <div className="custom-col">
                    <label>{t("Profile.email")}</label>
                    <input
                      type="email"
                      className="edit-media-inputs"
                      required
                      placeholder={t("Profile.email")}
                      value={email || ""}
                      readOnly
                    />
                  </div>
                </div>
                <br />

                <h5>{t("Profile.update_address")}</h5>

                {/* city and street address */}
                <div className="custom-row">
                  <div className="custom-col">
                    <label>{t("Profile.street_address")}</label>
                    <div className="email-input-container">
                      <input
                        type="text"
                        className="edit-media-inputs"
                        required
                        placeholder={t("Profile.street_address")}
                        value={streetAddress || ""}
                        onChange={handleInputChange}
                        autoComplete="new-address"
                      />
                      {!isPlacePredictionsLoading &&
                        placePredictions.length > 0 &&
                        !selectedPrediction &&
                        placesPopup && (
                          <div
                            className="suggestions-popup address"
                            style={{ maxHeight: "160px" }}
                          >
                            {placePredictions.map((suggestion, index) => (
                              <div
                                key={index}
                                className="suggestion-item"
                                onClick={() =>
                                  handlePredictionClick(suggestion)
                                }
                              >
                                <div className="suggestion-email">
                                  {suggestion.description}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="custom-col">
                    <label>{t("Profile.city")}</label>
                    <input
                      type="text"
                      className="edit-media-inputs"
                      required
                      placeholder={t("Profile.city")}
                      value={city || ""}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                </div>

                {/* country province/state zip/postal */}
                <div className="custom-row">
                  <div className="custom-col">
                    <label>{t("Profile.country")}</label>
                    <Select
                      value={countries.find(
                        (option) => option.label === country
                      )}
                      onChange={(option) => handleCountryChange(option.label)}
                      options={countries}
                      styles={styles}
                      required
                      menuPlacement="top"
                    />
                  </div>
                  {country === "Canada" ? (
                    <div className="custom-col">
                      <label>{t("Profile.province")}</label>
                      <Select
                        value={
                          provinces.find(
                            (option) => option.value === provinceOrState
                          ) || ""
                        }
                        onChange={(option) => setProvinceOrState(option.value)}
                        options={provinces}
                        styles={styles}
                        required
                        menuPlacement="top"
                      />
                    </div>
                  ) : country === "United States" ? (
                    <div className="custom-col">
                      <label>{t("Profile.state")}</label>
                      <Select
                        value={
                          usaStates.find(
                            (option) => option.value === provinceOrState
                          ) || ""
                        }
                        onChange={(option) => setProvinceOrState(option.value)}
                        options={usaStates}
                        styles={styles}
                        required
                        menuPlacement="top"
                      />
                    </div>
                  ) : (
                    <div className="custom-col">
                      <label>{t("Profile.province")}</label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        required
                        placeholder={t("Profile.province")}
                        value={provinceOrState || ""}
                        onChange={(e) => setProvinceOrState(e.target.value)}
                      />
                    </div>
                  )}

                  <div className="custom-col">
                    <label>
                      {country === "United States"
                        ? t("Profile.zip_code")
                        : t("Profile.postal_code")}
                    </label>
                    <input
                      type="text"
                      pattern={pattern}
                      title={
                        country === "United States"
                          ? "Please enter a valid zip code (e.g., 12345 or 12345-6789)."
                          : "Please enter a valid postal code (e.g., A1A 1A1)."
                      }
                      className="edit-media-inputs"
                      required
                      placeholder={
                        country === "United States"
                          ? t("Profile.zip_code")
                          : t("Profile.postal_code")
                      }
                      value={postalZipCode}
                      onChange={(e) => setPostalZipCode(e.target.value)}
                    />
                  </div>
                </div>

                {error && <div className="error-msg">{error}</div>}

                {/* submit btn */}
                <div className="register-btn-div profile">
                  <MDBBtn
                    type="submit"
                    rounded
                    className="register-btn"
                    disabled={loader}
                  >
                    {t("Profile.save_changes")}
                  </MDBBtn>
                </div>
              </form>
            )}

            {/* change password */}
            {step === "password" && (
              <form
                className="auth-form"
                autoComplete="off"
                onSubmit={handleChangePassword}
              >
                {passwordSuccessMsg && (
                  <div className="user-message">
                    {passwordSuccessMsg} {t("Profile.redirect_msg")}
                  </div>
                )}

                <div className="custom-row">
                  <div className="custom-col">
                    <label>{t("Profile.current_password")}</label>
                    <input
                      type="password"
                      className="edit-media-inputs"
                      required
                      placeholder={t("Profile.current_password")}
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                  </div>
                  <div className="custom-col">
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <span>{t("Signup.password")}</span>
                      <Tippy content={t("Signup.passwordInfoMsg")}>
                        <span style={{ marginLeft: `${5}px` }}>
                          <Icon icon={alertCircle} size={18} />
                        </span>
                      </Tippy>
                    </label>
                    <input
                      type="password"
                      className="edit-media-inputs"
                      required
                      placeholder={t("Profile.new_password")}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                  <div className="custom-col">
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <span>{t("Signup.confirm_password")}</span>
                      <Tippy content={t("Signup.confirmPasswordInfoMsg")}>
                        <span style={{ marginLeft: `${5}px` }}>
                          <Icon icon={alertCircle} size={18} />
                        </span>
                      </Tippy>
                    </label>
                    <input
                      type="password"
                      className="edit-media-inputs"
                      required
                      placeholder={t("Profile.confirm_new_password")}
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                  </div>
                </div>

                {errorInChangingPassword && (
                  <div className="error-msg">{errorInChangingPassword}</div>
                )}

                {/* submit btn */}
                <div className="register-btn-div profile">
                  <MDBBtn
                    type="submit"
                    rounded
                    className="register-btn"
                    disabled={loader}
                  >
                    {t("Profile.save_changes")}
                  </MDBBtn>
                </div>
              </form>
            )}

            {/* update profile image */}
            {step === "profileImage" && (
              <div className="update-profile-img-container">
                <div
                  className="profile-circle"
                  style={{
                    backgroundColor:
                      userData && userData.profileImage && isLoaded
                        ? "#f7f8fa"
                        : "#007c16",
                    border: "1px solid #007c16",
                  }}
                >
                  {userData && !userData.profileImage ? (
                    <h3>
                      {userData.firstName.charAt(0)}{" "}
                      {userData.lastName.charAt(0)}
                    </h3>
                  ) : (
                    <>
                      {!isLoaded && <h3>...</h3>}
                      <img
                        src={userData.profileImage}
                        alt="dp"
                        onLoad={() => setIsLoaded(true)}
                        style={{ display: !isLoaded ? "none" : "block" }}
                      />
                    </>
                  )}

                  <div className="upload-or-edit-icon-cirle">
                    <input
                      type="file"
                      id="profileImageUpload"
                      className="input-file"
                      accept=".jpeg,.jpg,.png"
                      onChange={(e) => {
                        handleProfilePhoto(e.target.files[0]);
                      }}
                      onClick={(e) => (e.target.value = null)}
                    />
                    <label htmlFor="profileImageUpload">
                      {!userData.profileImage ? (
                        <Icon icon={arrows_plus} size={20} />
                      ) : (
                        <Icon icon={software_pencil} size={20} />
                      )}
                    </label>
                  </div>
                </div>

                {/* file error in case */}
                {fileError && <div className="error-msg">{fileError}</div>}

                {userData.profileImage && (
                  <MDBBtn
                    type="button"
                    className="delete-btn"
                    onClick={handleDeletePhoto}
                    disabled={!isLoaded || deletePhotoLoading}
                  >
                    {deletePhotoLoading
                      ? `${t("Profile.removing")}`
                      : `${t("Profile.remove_photo")}`}
                  </MDBBtn>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileForm;
